import React from "react"
import Donate from './donate'

const aboutMe = () => (
    <div className="about"> 
      <h1 className="text-medium text-dark text-center text-weight-bold">Support my work</h1>
      <Donate />
    </div>
  )

export default aboutMe
