import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import NoImage from "../../assets/images/no-image.png"
import HeadPost from "../Blog/Post/headPost"

const Header = ({ post, published, readText, link }) => {
  const {
    frontmatter: { title, image, date },
    timeToRead,
    fields: { slug, langKey },
  } = post

  return (
    <div className="header">
      <div className="header-post-container header-half">
      <div className="post-container-body">
      <div className="header--text">
        <h1 className="text-weight-bold text-dark text-xlarge">{title}</h1>
      </div>
        {image ? (
            <Img
              className="cover cover-header"
              alt="cover-image"
              fluid={image.childImageSharp.fluid}
            />
          ) : (
            <img src={NoImage} className="cover cover-header" alt="cover" />
            )}
        </div>
        <div className="simple-flex">
        <HeadPost
        date={date}
        published={published}
        read={timeToRead}
        readText={readText}
        />
        <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="text-xsmall text-tertiary text-underline text-dark mr-1"
        >
          Follow me on Twitter
          </a>
          {langKey === "en" ? (
            <span>
            <Link
            to={`/fr/${slug}`}
            className="text-tertiary text-underline text-xsmall"
            >
            Traduire en francais
            </Link>
            </span>
            ) : null}
            </div>
            </div>
    </div>
  )
}

export default Header
