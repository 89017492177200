import React from "react"
import { Link } from "gatsby"

const prevNext = ({ prev, next }) => (
  <div className="mt-1 flex between">
    {prev && (
      <Link
        className="text-small text-third text-underline"
        to={prev.fields.slug}
      >
        {prev.frontmatter.title}
      </Link>
    )}
    {next && (
      <Link
        className="text-small text-third text-underline"
        to={next.fields.slug}
      >
        {next.frontmatter.title}
      </Link>
    )}
  </div>
)

export default prevNext
