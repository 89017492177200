import React from "react"
import { graphql } from "gatsby"
import Content from "./content"
import Layout from '../layout/en'
import Header from '../components/NavBar/header-post'

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
        langKey
      }
      frontmatter {
        title
        date(formatString: "MMM DD, Y")
        updated(formatString: "Y-MM-DD")
        tags
        keywords
        image {
            childImageSharp {
              fluid(maxWidth: 728) {
                ...GatsbyImageSharpFluid
            }
            resize(width: 1200) {
                src
            }
          }
        }
      }
      timeToRead
      excerpt(pruneLength: 250)
      html
      id
    }
    site {
      siteMetadata {
        social_links {
          link
        }
      }
    }
  }
`
const BlogTemplate = ({ data, pageContext, location }) => {
  
  const {
    markdownRemark: {
      fields: { langKey },
    }
    } = data
  const { prev, next } = pageContext

  return (
      <>
      <Header post={data.markdownRemark} />
      <Layout location={location}>
      {
        langKey === "fr" ? (
          <Content
            updatedText="Cet article a été mis à jour le "
            readText="de lecture"
            link={data.site.siteMetadata.social_links[0].link}
            post={data}
            prev={prev}
            next={next}
            />
            ) : (
              <Content
            post={data}
            updatedText="This article has been updated on "
            prev={prev}
            next={next}
            readText="read"
            link={data.site.siteMetadata.social_links[0].link}
          />
      )
    }
    </Layout>
    </>
  )
}

export default BlogTemplate
