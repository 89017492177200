import React, { Fragment, useRef } from "react"
import { DiscussionEmbed } from "disqus-react"

import Tags from "../components/Blog/Post/tags"
import PrevNext from "../components/Blog/Post/prevNext"
import Subscribe from "../components/Blog/Subscribe/subscribe"
import AboutMe from "../components/Blog/Contact/about-me"
import ProgressBar from "../components/Blog/Post/progressBar"
import SEO from "../components/seo"

const Content = ({
  post,
  prev,
  next,
  updatedText
}) => {
  const {
    frontmatter: { title, updated, image, tags, keywords },
    html,
    excerpt,
    id,
    fields: { slug },
  } = post.markdownRemark
  const disqusShortname = "ibrahima-ndaw"
  const disqusConfig = {
    identifier: id,
    title,
  }
  const contentLength = useRef(null)
  return (
    <Fragment>
      <SEO title={title} description={excerpt} keywords={keywords} slug={slug} image={image} />
      <section ref={contentLength} className="container-blog">
      <ProgressBar target={contentLength} />          
        {updated ? (
          <p style={{ fontStyle: "italic" }}>
            {updatedText}
            {updated}
          </p>
        ) : null}
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <div className="mt-2 responsive-flex">
          <Tags tags={tags} />
          <PrevNext prev={prev && prev.node} next={next && next.node} />
          <div className="card card-aside flex between mt-2">
            <AboutMe />
            <Subscribe buttonColor="btn-email-primary">
              <h1 className="text-medium text-dark text-center text-weight-bold">
                Get articles in your inbox
              </h1>
            </Subscribe>
          </div>
        </div>
        <div className="mt-1">
          <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
        </div>
      </section>
    </Fragment>
  )
}

export default Content
