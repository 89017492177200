import React, { useState, useEffect } from "react"

function ProgressBar  ({target}) { 
 const [readingProgress, setReadingProgress] = useState(0);
 
 useEffect(() => {
    window.addEventListener("scroll", debounce(scrollListener));
    return () => window.removeEventListener("scroll", scrollListener);
 });

 const scrollListener = () => {
 if (!target.current) return;

 const element = target.current;
 const totalHeight = element.clientHeight - element.offsetTop - (window.innerHeight);
 const windowScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

 if (windowScrollTop === 0) {
 return setReadingProgress(0);
 }

 if (windowScrollTop > totalHeight) {
 return setReadingProgress(100);
 }

 setReadingProgress((windowScrollTop / totalHeight) * 100);
 };

// This function handle the scroll performance issue
const debounce = (func, wait = 20, immediate = true) => {
let timeOut;
return () => {
    let context = this,
    args = arguments;
    const later = () => {
    timeOut = null;
    if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeOut;
    clearTimeout(timeOut);
    timeOut = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
};
};


    return (
        readingProgress > 0 ?
    <div className="progress-container">
        <div className="progress-bar" style={{width: `${readingProgress}%`}} />
    </div>: null
    )
}

export default ProgressBar;